import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Combobox, IconUser, Link, Select } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/dropdown/code",
  "title": "Dropdown - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "select-single-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Select (single selection)`}<a parentName="h4" {...{
        "href": "#select-single-selection",
        "aria-label": "select single selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const options = [{ label: 'Plutonium' }, { label: 'Americium' }, { label: 'Copernicium' }, { label: 'Berkelium' }];
  return (
    <>
      <Select required label="Label" helper="Assistive text" placeholder="Placeholder" options={options} />
      <Select
        disabled
        label="Label"
        helper="Assistive text"
        placeholder="Placeholder"
        options={options}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
      <Select
        invalid
        required
        label="Label"
        error="Error description"
        placeholder="Placeholder"
        options={options}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
      <Select
        required
        label="Label"
        helper="Assistive text"
        placeholder="Placeholder"
        icon={<IconUser />}
        options={[{ label: 'Adam' }, { label: 'Caitlyn' }, { label: 'Jack' }, { label: 'Sally' }]}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "select-multi-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Select (multi-selection)`}<a parentName="h3" {...{
        "href": "#select-multi-selection",
        "aria-label": "select multi selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Select
  multiselect
  required
  label="Label"
  placeholder="Placeholder"
  helper="Assistive text"
  options={[
    { label: 'Plutonium' },
    { label: 'Americium' },
    { label: 'Copernicium' },
    { label: 'Berkelium' },
  ]}
  clearButtonAriaLabel="Clear all selections"
  selectedItemRemoveButtonAriaLabel="Remove \${value}"
/>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "combobox-single-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Combobox (single selection)`}<a parentName="h3" {...{
        "href": "#combobox-single-selection",
        "aria-label": "combobox single selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const options = [
    { label: 'Americium' },
    { label: 'Berkelium' },
    { label: 'Californium' },
    { label: 'Copernicium' },
    { label: 'Einsteinium' },
    { label: 'Fermium' },
    { label: 'Mendelevium' },
    { label: 'Plutonium' },
  ]
  return (
    <>
      <Combobox required label="Label" helper="Assistive text" placeholder="Placeholder" toggleButtonAriaLabel="Toggle menu" options={options} />
      <Combobox
        disabled
        label="Label"
        helper="Assistive text"
        placeholder="Placeholder"
        toggleButtonAriaLabel="Toggle menu"
        options={options}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
      <Combobox
        invalid
        required
        label="Label"
        error="Error description"
        placeholder="Placeholder"
        toggleButtonAriaLabel="Toggle menu"
        options={options}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
      <Combobox
        required
        label="Label"
        helper="Assistive text"
        placeholder="Placeholder"
        toggleButtonAriaLabel="Toggle menu"
        icon={<IconUser />}
        options={[
          { label: 'Adam' },
          { label: 'Allie' },
          { label: 'Bob' },
          { label: 'Caitlyn' },
          { label: 'Jack' },
          { label: 'Olivia' },
          { label: 'Sally' },
          { label: 'Sam' },
        ]}
        style={{ marginTop: 'var(--spacing-xs)' }}
      />
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "combobox-multi-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Combobox (multi-selection)`}<a parentName="h3" {...{
        "href": "#combobox-multi-selection",
        "aria-label": "combobox multi selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Combobox
  multiselect
  required
  label="Label"
  helper="Assistive text"
  placeholder="Placeholder"
  options={[
    { label: 'Americium' },
    { label: 'Berkelium' },
    { label: 'Californium' },
    { label: 'Copernicium' },
    { label: 'Einsteinium' },
    { label: 'Fermium' },
    { label: 'Mendelevium' },
    { label: 'Plutonium' },
  ]}
  clearButtonAriaLabel="Clear all selections"
  selectedItemRemoveButtonAriaLabel="Remove \${value}"
  toggleButtonAriaLabel="Toggle menu"
/>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dropdowns-select--default" mdxType="Link">{`View Select in Storybook`}</Link>{` `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dropdowns-combobox--default" mdxType="Link">{`View Combobox in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/dropdown" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-dropdowns-combobox--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <p>{`Also, note that this component is an input. All features supported by the HDS TextInput are also supported by this component. See `}<InternalLink href="/components/text-input" mdxType="InternalLink">{`TextInput documentation page`}</InternalLink>{` for more information.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clearable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the clear selections button is enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the dropdown is disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An icon to be shown in the beginning of the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text label for the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The array of options shown in the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`OptionType[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short hint displayed in the input before the user enters a value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the input is marked as required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`multiselect`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the user can select multiple values.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      